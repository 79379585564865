<template>
  <Auth>
    <div v-if="!isLoading">
      <div v-if="success">
        <h1 class="title title--2">
          Votre email a été confirmé
        </h1>
        <p>
          <router-link class="button" :to="{ name: 'Welcome' }">
            J'accède à Solency
          </router-link>
        </p>
      </div>

      <div v-else>
        <h1 class="title title--2">
          Votre email n'a pas pu être confirmé
        </h1>
        <p>
          <router-link class="button" :to="{ name: 'Connection' }">
            Connexion
          </router-link>
        </p>
      </div>
    </div>

    <div v-else>
      <p>
        <Loader>
          Chargement
        </Loader>
      </p>
    </div>
  </Auth>
</template>

<script>
import { api } from '@/lib/api';
import { Auth } from '@/components';
import { Loader } from '@/components/common';
import { mapActions } from 'vuex';

export default {
  components: { Auth, Loader },

  data() {
    return {
      isLoading: false,
      success: false,
    };
  },

  async mounted() {
    this.isLoading = true;
    try {
      const token = this.$route.query.token;
      await api.put(`/email_confirmation_tokens/${token}`, {
        verified: true,
      });

      this.activateUser();

      this.isLoading = false;
      this.success = true;
    } catch {
      this.isLoading = false;
      this.success = false;
    }
  },

  methods: {
    ...mapActions({
      activateUser: 'auth/activate',
    }),
  },
};
</script>
